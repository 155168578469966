<template>
  <v-app id="create-client">
    <v-sheet height="100%" class="grey lighten-4 pb-10">
      <div class="ma-6">
        <v-btn class="close_btn_custom" icon to="/products">
          <v-icon color="black" large>mdi-close</v-icon>
        </v-btn>

        <v-btn
          absolute
          right
          x-large
          color="#101928"
          dark
          height="48px"
          width="160px"
          class="mr-2 text-capitalize"
          style="letter-spacing: 0.5px"
        >
          Save
        </v-btn>
      </div>

      <div class="d-flex justify-center">
        <h2 style="font-size: 1.7rem; letter-spacing: 0.6px">
          Add new product
        </h2>
      </div>

      <v-sheet
        max-width="1000"
        class="mx-auto pt-8"
        color="transparent"
        elevation="0"
      >
        <v-row>
          <v-col cols="8">
            <v-card class="rounded-lg pa-">
              <h3 class="py-4 px-6">Basic info</h3>
              <v-divider></v-divider>
              <v-card-text class="px-6">
                <div class="d-flex flex-column">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <label class="dialog_label">Product name</label>
                      <v-text-field solo class="mt-1"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <label class="dialog_label"
                        >Product barcode (Optional)</label
                      >
                      <v-text-field
                        solo
                        class="mt-1"
                        placeholder="UPC, EAN, GTIN"
                      ></v-text-field>
                    </v-col>

                    <!-- <v-col cols="12" sm="12" class="pt-0">
                      <label class="dialog_label">Product brand</label>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0 brand-link">
                      <a class="brand-link" href="javascript:void();"
                        >Select a brand</a
                      >
                    </v-col> -->

                    <v-col cols="12" sm="6" class="pt-0">
                      <label class="dialog_label">Measure</label>
                      <v-select
                        :items="genders_list"
                        solo
                        class="mt-1"
                        placeholder="Select an option"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" class="pt-0">
                      <label class="dialog_label">Amount</label>
                      <v-text-field
                        solo
                        class="mt-1"
                        placeholder="Amount"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <label class="dialog_label">Short description</label>
                      <v-text-field solo class="mt-1"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <label class="dialog_label">Product description</label>
                      <v-textarea
                        solo
                        auto-grow
                        rows="4"
                        row-height="27"
                        class="mt-1"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <label class="dialog_label">Product category</label>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0 brand-link">
                      <!-- <a class="brand-link" href="javascript:void();"
                        >Select a category</a
                      > -->
                      <v-select
                        :items="categoryList"
                        solo
                        class="mt-1"
                        v-model="selectedCategory"
                        placeholder="Select an category"
                      ></v-select>
                      <v-row class="mb-2 ml-1">
                        <v-dialog v-model="dialog" persistent max-width="600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click="newCategory = ''"
                            >
                              Add New category
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="text-h5">New category</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12">
                                    <v-text-field
                                      label="Category Name"
                                      required
                                      v-model="newCategory"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="
                                  dialog = false;
                                  newCategory = '';
                                "
                              >
                                Close
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="
                                  dialog = false;
                                  addnewCategory(newCategory);
                                "
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>

            <v-card class="rounded-lg mt-7">
              <h3 class="py-4 px-6">Pricing</h3>
              <v-divider></v-divider>
              <v-card-text class="px-6">
                <div class="d-flex flex-column">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <label class="dialog_label">Supply price</label>
                      <v-text-field solo class="mt-1"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <label class="dialog_label">Retail sales</label>
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0">
                      <p class="">Allow sales of this product at checkout.</p>
                    </v-col>

                    <div class="d-flex flex-column px-6 pb-2">
                      <v-row>
                        <v-col cols="12" sm="12" class="pt-0">
                          <v-switch
                            class="custom_switch"
                            :v-model="true"
                            :value="true"
                            :input-value="true"
                            label="Enable retail sales"
                            color="#00a36d"
                            inset
                            @change="toggleRetailSales($event)"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </div>

                    <div v-if="retail_sales">
                      <v-row>
                        <v-col cols="12" sm="4" class="pt-0 ml-4">
                          <label class="dialog_label">Retail price</label>
                          <v-text-field
                            solo
                            class="mt-1"
                            placeholder="INR"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4" class="pt-0">
                          <label class="dialog_label">Special price</label>
                          <v-text-field
                            solo
                            class="mt-1"
                            placeholder="INR"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="3" class="pt-0">
                          <label class="dialog_label">Markup</label>
                          <v-text-field
                            solo
                            class="mt-1"
                            placeholder="%"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-col cols="12" sm="12" class="pt-0">
                        <label class="dialog_label">Tax</label>
                        <v-select
                          :items="genders_list"
                          solo
                          class="mt-1"
                          placeholder="Select an option"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="12" class="pt-0">
                        <label class="dialog_label"
                          >Team member commission</label
                        >
                      </v-col>

                      <v-col cols="12" sm="12" class="pt-0">
                        <p class="">
                          Calculate team member commission when the product is
                          sold.
                        </p>
                      </v-col>

                      <div class="d-flex flex-column px-6 pb-2">
                        <v-row>
                          <v-col cols="12" sm="12" class="pt-0">
                            <v-switch
                              class="custom_switch"
                              :v-model="true"
                              :value="true"
                              :input-value="true"
                              label="Enable team member commission"
                              color="#00a36d"
                              inset
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>

            <v-card class="rounded-lg mt-7">
              <h3 class="py-4 px-6">Inventory</h3>
              <v-col cols="12" sm="12" class="pt-0 ml-3">
                <p class="">
                  Manage stock levels of this product through Fresha.
                </p>
              </v-col>
              <v-divider></v-divider>
              <v-card-text class="px-6">
                <div class="d-flex flex-column">
                  <v-row>
                    <v-row class="bottom-border">
                      <v-col
                        cols="11"
                        sm="11"
                        class="mt-3 ml-2"
                        v-for="(item, index) in SKU"
                        :key="index"
                      >
                        <label class="dialog_label"
                          >SKU (Stock Keeping Unit)</label
                        >
                        <div class="d-flex Stock">
                          <v-text-field
                            solo
                            class="mt-1"
                            v-model="item.sku"
                          ></v-text-field>
                          <span
                            class="close-button"
                            v-if="SKU.length > 1"
                            @click="remove_item('SKU', index)"
                            ><i class="fa fa-times"
                          /></span>
                        </div>
                      </v-col>

                      <v-col cols="12" sm="12" class="pt-0 ml-2 brand-link">
                        <a class="brand-link" href="javascript:void();"
                          >Generate SKU automatically</a
                        >
                      </v-col>

                      <v-col cols="12" sm="12" class="pt-0 ml-2 brand-link">
                        <a
                          class="brand-link"
                          href="javascript:void();"
                          @click="add_Information('SKU')"
                          >Add another SKU code</a
                        >
                      </v-col>
                    </v-row>

                    <!-- <v-row class="bottom-border">
                      <v-col cols="12" sm="12" class="mt-3 ml-2">
                        <label class="dialog_label">Supplier</label>
                      </v-col>

                      <v-col cols="12" sm="12" class="Supplier">
                        <a class="brand-link ml-2" href="javascript:void();"
                          >Select a Supplier</a
                        >
                      </v-col>
                    </v-row> -->

                    <v-row class="bottom-border" style="display: contents">
                      <v-col cols="12" sm="12" class="mt-3">
                        <label class="dialog_label">Stock quantity</label>
                        <div class="d-flex flex-column">
                          <v-row>
                            <v-col cols="12" sm="12" class="pt-0">
                              <v-switch
                                class="custom_switch"
                                :v-model="true"
                                :value="true"
                                :input-value="true"
                                label="Track stock quantity"
                                color="#00a36d"
                                inset
                                @change="toggleStockQuantity($event)"
                              ></v-switch>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>

                      <v-col cols="12" sm="12" v-if="stock_quantity">
                        <label class="dialog_label"
                          >Current stock quantity</label
                        >
                        <v-text-field
                          solo
                          class="mt-1"
                          placeholder="0"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-col cols="12" sm="12" class="mt-3" v-if="stock_quantity">
                      <label class="dialog_label"
                        >Low stock and reordering</label
                      >
                    </v-col>

                    <v-col cols="12" sm="12" class="pt-0" v-if="stock_quantity">
                      <p class="">
                        Fresha will automatically notify you and pre-fill the
                        reorder quantity set for future purchase orders.<span
                          ><a class="brand-link" href="javascript:void();"
                            >Learn more</a
                          ></span
                        >
                      </p>
                    </v-col>

                    <v-col cols="12" sm="6" class="pt-0" v-if="stock_quantity">
                      <label class="dialog_label">Low stock level</label>
                      <v-text-field
                        solo
                        class="mt-1"
                        placeholder="0"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" class="pt-0" v-if="stock_quantity">
                      <label class="dialog_label">Reorder quantity</label>
                      <v-text-field
                        solo
                        class="mt-1"
                        placeholder="0"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" sm="6" class="pt-0" v-if="stock_quantity">
                      <p class="low-stock">
                        The level to get notified to reorder
                      </p>
                    </v-col>

                    <v-col cols="6" sm="6" class="pt-0" v-if="stock_quantity">
                      <p class="low-stock">The default amount to order</p>
                    </v-col>

                    <v-col cols="12" sm="12" v-if="stock_quantity">
                      <div class="d-flex flex-column">
                        <v-row>
                          <v-col cols="12" sm="12" class="pt-0">
                            <v-switch
                              class="custom_switch"
                              :v-model="true"
                              label="Receive low stock notifications."
                              color="#00a36d"
                              inset
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card class="rounded-lg pa-">
              <h3 class="pt-4 px-6">Product photos</h3>
              <p
                class="px-6 pt-1 pb-4 text-body-1"
                style="letter-spacing: 0.3px !important"
              >
                Drag and drop a photo to change the order.
              </p>

              <v-divider></v-divider>

              <div class="d-flex flex-column px-6 pb-2">
                <v-row>
                  <v-col cols="12" sm="12" class="pt-0">
                    <div class="col-auto me-auto">
                      <div class="w-auto mb-4">
                        <div
                          class="round-camera-btn"
                          :style="[
                            {
                              'background-image': 'url(' + product_img + ')',
                            },
                          ]"
                        >
                          <input
                            type="file"
                            id="img"
                            name="img"
                            accept="image/*"
                            @change="onFileChange"
                          />
                          <img
                            v-bind:style="
                              product_img === ''
                                ? 'display: block;'
                                : 'display: none;'
                            "
                            src="../assets/camera.png"
                            class="img-fluid camera-png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>

    <v-divider></v-divider>
  </v-app>
</template>

<script>
export default {
  name: "NewProduct",

  data: () => ({
    genders_list: [
      "Select an option",
      "Female",
      "Male",
      "Non-binary",
      "Prefer not to say",
    ],
    categoryList: ["Female", "Male", "Non-binary", "Prefer not to say"],
    selectedCategory: "",
    newCategory: "",
    product_img: "",
    retail_sales: true,
    stock_quantity: true,
    SKU: [{ sku: "" }],
    dialog: false,
  }),
  methods: {
    /* Add Information */
    add_Information(type) {
      if (type === "SKU") {
        this.SKU.push({ sku: "" });
      }
    },

    /* Remove Information */
    remove_item(type, id) {
      if (type === "SKU") {
        if (this.SKU.length > 1) {
          this.SKU.splice(id, 1);
        }
      }
    },

    toggleRetailSales(event) {
      if (event) {
        this.retail_sales = true;
      } else {
        this.retail_sales = false;
      }
    },

    addnewCategory(newCategory) {
      if (newCategory !== "") {
        this.categoryList.push(this.newCategory);
        this.selectedCategory = this.newCategory;
      }
    },

    toggleStockQuantity(event) {
      if (event) {
        this.stock_quantity = true;
      } else {
        this.stock_quantity = false;
      }
    },
    onFileChange(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.product_img = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>
<style scoped>
.brand-link {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  color: #037aff;
  text-decoration: none !important;
}

.round-camera-btn {
  margin-top: 10px;
  border: 3px solid #cde4ff;
  width: calc(50px + (150 - 110) * ((550vw - 320px) / (1920 - 320)));
  height: calc(50px + (150 - 110) * ((550vw - 320px) / (1920 - 320)));
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cde4ff;
  color: #037aff;
  transition: background-color 300ms ease-in-out;
}

.camera-png {
  width: calc(20px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
  position: absolute;
}

.round-camera-btn input {
  width: 100% !important;
}

.round-camera-btn {
  background-position: center;
  background-size: cover;
}

input[type="file"] {
  font-size: 0;
  opacity: 0;
  height: 100%;
  display: block;
  cursor: pointer;
}

.round-camera-btn input {
  position: relative;
  z-index: 1;
}
.low-stock {
  margin-top: -35px;
}

.bottom-border {
  border-bottom: 1px solid #e7e8e9;
}
.Supplier {
  margin-top: -15px;
}
.close-button {
  margin-top: 19px;
  margin-left: 21px;
  cursor: pointer;
  color: #da2346;
}
.Stock {
  margin-bottom: -22px;
}
</style>